<template>
  <b-modal
    :id="idModalCreate"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    no-close-on-backdrop
    centered
    @ok="handleCreateStruct"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">

        <b-form-group>
          <label for="InputHelp">Tên quy chuẩn<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="customMessages"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <b-form-group>
      <label for="InputHelp">Quy chuẩn cha</label>
      <LightSelect
        v-model="queryBody.parentId"
        :idMinus="dataDetail.id"
        :isMinus="!dataDetail.id"
      />
    </b-form-group>
    <b-form-group>
      <label for="InputHelp">Giá trị</label>
      <b-form-input
        v-model="queryBody.value"
        type="number"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LightSelect from './LightSelect.vue'
// import OrganizationSelect from '../../../users/pages/components/OrganizationSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    LightSelect,
    // OrganizationSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalCreate: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      queryBody: {
        name: '',
        parentId: '',
        value: 0,
      },
      parentChildrenId: '',
      dataDetailStrcut: null,
      customMessages: {
        required: 'Tên quy chuẩn là bắt buộc',
      },
    }
  },
  watch: {
    dataDetail: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            name: this.dataDetail.name,
            parentId: this.dataDetail.parentId,
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            name: '',
            parentId: this.dataDetail.id,
          }
        }
      },
    },

  },
  methods: {
    handleCreateStruct(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateStruct', this.queryBody)
        }
      })
    },
    resetState() {
      this.queryBody = {
        name: '',
        parentId: '',
        value: 0,
      }
    },
  },
}
</script>

<style>

</style>
