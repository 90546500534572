<template>
  <div
    id="organizationStruct"
  >
    <!--Phần header của bảng -->
    <button-all-header
      :contentBtnAdd="'Thêm quy chuẩn'"
      :arrayExcel="[]"
      :hideDelete="false"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      class="custtom-header"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @importFile="() => {}"
      @clickAdd="handleOpenModalCreate"
      @search="search($event)"
    />
    <div class="permission-content">
      <liqui-tree
        ref="tree"
        class="tree-mini"
        :data="dataList"
        :options="getTreeOption"
      >
        <div
          slot-scope="{ node }"
          class="tree-text d-flex align-items-center"
          @mouseleave="mouseOutItem"
        >
          <template>
            <span
              @click.stop
            >
              <div class="d-flex align-items-center">
                <span class="flex items-center">
                  <span class="tree-text">{{ node.text }}</span>
                  <b-dropdown
                    v-if="treeOptions.type==='normal'"
                    class="node-action ml-1"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('add')===true"
                      @click="handleOpenModal(node.id)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span>Thêm quy chuẩn</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('edit')===true"
                      @click="handleOpenDetail(node.id)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Chỉnh sửa  </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="treeOptions.action&&treeOptions.action.includes('delete')===true"
                      @click="handleOpenModalDelete(node.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Xóa</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
            </span>
          </template>
        </div>
      </liqui-tree>
    </div>

    <!-- Modal tạo cơ cấu tổ chức mới -->
    <ModalCreateLight
      :idModalCreate="modalCreate"
      :type="modalType"
      :dataListChildren="dataListChildren"
      :title="modalType === 'add'? 'Thêm quy chuẩn ánh sáng': 'Chỉnh sửa quy chuẩn ánh sáng'"
      :dataDetail="dataDetail"
      @handleCreateStruct="handleCreateStruct"
    />

    <!-- modal xóa người dùng-->
    <ModalDeleteLight
      idModalDelete="confirmModalId"
      :deletedStructId="deletedStructId"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import readXlsxFile from 'read-excel-file'
import LiquiTree from 'liquor-tree'
import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalCreateLight from '../component/ModalCreateLight.vue'
import ModalDeleteLight from '../component/ModalDeleteLight.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    LiquiTree,
    ButtonComponent,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BBadge,
    ButtonAllHeader,
    ModalCreateLight,
    ModalDeleteLight,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      treeOptions: {
        type: 'normal',
        action: [
          'add',
          'add-postion-job',
          'add-from-file',
          'edit',
          'delete',
        ],
      },
      parentId: '',
      modalContent: '',
      deletedStructId: '',
      idMinus: '',
      isMinus: false,
      modalCreate: 'modalCreateLight',
      dataDetail: {},
      parentOfParentId: '',
      modalType: 'add',
      modalIdPosition: 'modal-id-position',
      idDetail: '',
    }
  },
  computed: {
    getTreeOption() {
      return {
        // minFetchDelay: 200,
        fetchData: node => this.fetchNode(node),
        propertyNames: {
          text: 'name',
          children: 'childrens',
        },
      }
    },
    ...mapGetters('light', ['dataListChildren', 'dataListUpdated', 'dataList']),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('light', ['doFetchChildren', 'doFetchUpdated', 'doFetchList']),
    // di chuyển chuột khỏi nhánh
    mouseOutItem(event) {
      const treeContent = event.target.closest('.tree-content')
      const els = treeContent.getElementsByClassName('show')
      if (els.length > 0) {
        for (let i = els.length - 1; i >= 0; i -= 1) {
          els[i].classList.remove('show')
        }
      }
    },

    // danh sách dữ liệu từ node được chọn đến gốc
    async fetchData() {
      const model = {
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchList(model)
      this.$nextTick(() => {
        this.dataList.forEach(element => {
          element.isBatch = true
        })
        this.$refs.tree.setModel(this.dataList)
      })
    },
    recursiveData(data) {
      data.state = {
        checked: false,
        expanded: false,
      }
      data.data = {
        selectOnly: true,
      }
      if (this.value.find(x => x === data.id)) {
        data.state.checked = true
      }
      if (data.childrens && data.childrens.length > 0) {
        data.state.expanded = true
        // data.isBatch = true
        data.childrens.forEach(element => {
          this.recursiveData(element)
        })
      }
    },

    // lấy dữ liệu node con cơ cấu tổ chức
    async fetchNode(node) {
      const model = {
        id: node.id,
        idMinus: this.idMinus,
        isMinus: this.isMinus,
      }
      await this.doFetchChildren(model)
      this.dataListChildren.forEach(element => {
        element.isBatch = true
      })
      return new Promise(resolve => {
        resolve(this.dataListChildren)
      })
    },

    async handleOpenDetail(id) {
      this.modalType = 'edit'
      this.parentId = id
      this.$bvModal.show(this.modalCreate)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_LIGHT}${this.parentId}`)
      this.dataDetail = data
    },

    // Mở modal taoj mowis item
    async handleOpenModal(id) {
      this.modalType = 'add'
      this.parentId = id
      this.$bvModal.show(this.modalCreate)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_LIGHT}${this.parentId}`)
      this.dataDetail = data
    },

    // Mở modal taoj mowis item
    handleOpenModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreate)
    },

    // Mở modal xóa
    handleOpenModalDelete(id) {
      this.deletedStructId = id
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show('confirmModalId')
    },

    // Action tạo mới và chỉnh sửa cơ cấu tổ chức
    async handleCreateStruct(val) {
      if (this.modalType === 'edit') {
        const payload = {
          ...val,
          id: this.parentId,
          value: parseInt(val.value, 10),
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_LIGHT, payload).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Cập nhật thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchData()
            this.$bvModal.hide(this.modalCreate)
          } else {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      }
      if (this.modalType === 'add') {
        const data = {
          ...val,
          value: parseInt(val.value, 10),
        }
        this.$hideAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_LIGHT, data).then(res => {
          if (res.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Tạo thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalCreate)
            this.fetchData()
          }
        }).catch(err => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(err.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Action tạo mới cơ cấu tổ chức
    async deleteAction(val, validate) {
      const modal = { ids: this.deletedStructId, newId: val }
      validate.validate().then(async success => {
        if (success) {
          this.$showAllPageLoading()
          await axiosApiInstance.delete(ConstantsApi.DELETE_LIGHT, { params: modal }).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast('Xóa thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.$bvModal.hide('confirmModalId')
              this.fetchData()
            } else {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(`${res.data?.errors[0]}`, {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
          })
        }
      })
    },

  },
}
</script>

<style lang="scss" scope>
@import "@/assets/scss/tree/tree.scss";

#organizationStruct {
  .button-import {
    .input-file {
      display: none;
    }
  }
  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }
  .search-input {
    width: 400px;
  }

  .permission-content {
    border: 1px solid #A6A8B0;
    box-sizing: border-box;
    border-radius: 6px;
    height: 100%;
    .items-center {
      .dropdown-menu {
        margin-top: 0.2rem !important;
      }
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }

  .tree-mini {
    .tree-content {
      .tree-text {
        color: #2E3A4A;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .tree-children {
      .tree-text {
        color: #2E3A4A;
        font-weight: normal !important;
      }
    }
  }
}
</style>
