<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      :defaultActive="activeTab"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix ">
      <ListDust
        v-if="isRendered('bui')"
        class="tab-pane"
        :class="{'active':activeTab==='bui'}"
      />
      <ListMagnetic
        v-if="isRendered('dien-tu')"
        class="tab-pane"
        :class="{'active':activeTab==='dien-tu'}"
      />
      <ListNoise
        v-if="isRendered('tieng-on')"
        class="tab-pane"
        :class="{'active':activeTab==='tieng-on'}"
      />
      <ListRadition
        v-if="isRendered('buc-xa-tia-x')"
        class="tab-pane"
        :class="{'active':activeTab==='buc-xa-tia-x'}"
      />
      <ListTemperature
        v-if="isRendered('nhiet-do')"
        class="tab-pane"
        :class="{'active':activeTab==='nhiet-do'}"
      />
      <ListShake
        v-if="isRendered('rung')"
        class="tab-pane"
        :class="{'active':activeTab==='rung'}"
      />
      <ListChemistry
        v-if="isRendered('hoa-chat')"
        class="tab-pane"
        :class="{'active':activeTab==='hoa-chat'}"
      />
      <ListLight
        v-if="isRendered('anh-sang')"
        class="tab-pane"
        :class="{'active':activeTab==='anh-sang'}"
      />
      <ListAnother
        v-if="isRendered('khac')"
        class="tab-pane"
        :class="{'active':activeTab==='khac'}"
      />

    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/occupational-safety-health/declare-equip/constants/ConstantsApi'
import ListDust from './tab/ListDust.vue'
import ListMagnetic from './tab/ListMagnetic.vue'
import ListNoise from './tab/ListNoise.vue'
import ListRadition from './tab/ListRadition.vue'
import ListTemperature from './tab/ListTemperature.vue'
import ListChemistry from './tab/ListChemistry.vue'
import ListLight from './tab/ListLight.vue'
import ListShake from './tab/ListShake.vue'
import ListAnother from './tab/ListAnother.vue'

export default {
  components: {
    BButton,
    MyTabs,
    ListDust,
    ListMagnetic,
    ListNoise,
    ListRadition,
    ListTemperature,
    ListShake,
    ListChemistry,
    ListAnother,
    ListLight,
  },
  data() {
    return {
      detailId: '',
      detailData: {},
      activeTab: null,
      listTabs: [
        {
          key: 'bui',
          title: 'Bụi',
          icon: 'CloudSnowIcon',
          isRendered: false,
        },
        {
          key: 'dien-tu',
          title: 'Điện từ trường tần số công nghiệp',
          icon: 'ZapIcon',
          isRendered: false,
        },
        {
          key: 'tieng-on',
          title: 'Tiếng ồn',
          icon: 'Volume2Icon',
          isRendered: false,
        },
        {
          key: 'buc-xa-tia-x',
          title: 'Bức xạ tia x',
          icon: 'ZapIcon',
          isRendered: false,
        },
        {
          key: 'nhiet-do',
          title: 'Nhiệt độ',
          icon: 'CloudSnowIcon',
          isRendered: false,
        },
        {
          key: 'rung',
          title: 'Rung',
          icon: 'ActivityIcon',
          isRendered: false,
        },
        {
          key: 'anh-sang',
          title: 'Ánh sáng',
          icon: 'SunIcon',
          isRendered: false,
        },
        {
          key: 'hoa-chat',
          title: 'Hóa chất',
          icon: 'SunIcon',
          isRendered: false,
        },
        {
          key: 'khac',
          title: 'Khác',
          icon: 'SunIcon',
          isRendered: false,
        },

      ],
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'bui'
      // this.$router.push({ name: 'observation-monitor-type', params: { type: this.activeTab } }).catch(() => {})
      this.listTabs[0].isRendered = true
    }
  },
  methods: {
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
      this.$router.push({ name: 'observation-monitor-type', params: { type: activeTab } }).catch(() => {})
    },
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
  },
}
</script>
